body {
  background-color: black;
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

header {
  text-align: center;
}
